import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, Card, CardContent, CardActions, Button } from '@mui/material';
import { getFirestore, collection, getDocs, deleteDoc, doc, getDoc, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from '../../../components/ConfirmDialogue';

const Events = () => {
  const [events, setEvents] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      const db = getFirestore();
      const eventsSnapshot = await getDocs(collection(db, 'events'));
      const eventsData = eventsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setEvents(eventsData);
    };

    fetchEvents();
  }, []);

  const handleDelete = async (id) => {
    const db = getFirestore();
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      try {
        const roleDoc = await getDoc(doc(db, 'roles', user.uid));
        if (roleDoc.exists() && roleDoc.data().roles && roleDoc.data().roles.includes('admin')) {
          const eventDoc = await getDoc(doc(db, 'events', id));
          if (eventDoc.exists()) {
            await setDoc(doc(db, 'backup_events', id), eventDoc.data());
            await deleteDoc(doc(db, 'events', id));
            setEvents(events.filter((event) => event.id !== id));
          }
        } else {
          console.error('User does not have admin privileges');
        }
      } catch (error) {
        console.error('Error fetching user role or deleting document:', error);
      }
    } else {
      console.error('No user is signed in');
    }
  };

  const handleOpenDialog = (id) => {
    setEventToDelete(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setEventToDelete(null);
    setOpenDialog(false);
  };

  const handleConfirmDelete = () => {
    if (eventToDelete) {
      handleDelete(eventToDelete);
      handleCloseDialog();
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Manage Events
      </Typography>
      <Button variant="contained" color="primary" onClick={() => navigate('/create-event')}>
        Create New Event
      </Button>
      <Grid container spacing={3} sx={{ mt: 2 }}>
        {events.map((event) => (
          <Grid item xs={12} sm={12} md={6} key={event.id}>
            <Card>
              <CardContent>
                <Typography variant="h6">{event.title}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {new Date(event.startDate.toDate()).toLocaleDateString()} - {new Date(event.endDate.toDate()).toLocaleDateString()}
                </Typography>
                <Typography variant="body2" dangerouslySetInnerHTML={{ __html: event.description }} />
              </CardContent>
              <CardActions>
                {/* <Button size="small" color="primary" onClick={() => navigate(`/edit-event/${event.id}`)}>
                  Edit
                </Button> */}
                <Button size="small" color="secondary" onClick={() => handleOpenDialog(event.id)}>
                  Delete
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <ConfirmDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
        title="Confirm Deletion"
        content="Are you sure you want to delete this event? This action cannot be undone."
      />
    </Container>
  );
};

export default Events;
