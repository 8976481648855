import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Checkbox, useTheme } from '@mui/material';
import { useConsent } from '../context/ConsentContext';

const CookieConsent = () => {
  const { handleConsentChange, consentDialogOpen, closeConsentDialog } = useConsent();
  const [showCustomize, setShowCustomize] = useState(false);
  const [consentState, setConsentState] = useState({ functional: true, analytics: false, thirdParty: false });

  useEffect(() => {
    setConsentState({ functional: true, analytics: false, thirdParty: false });
  }, []);

  const handleChange = (event) => {
    setConsentState({ ...consentState, [event.target.name]: event.target.checked });
  };

  const handleSave = () => {
    handleConsentChange(consentState);
    closeConsentDialog();
  };

  const handleAcceptAll = () => {
    const allTrue = { functional: true, analytics: true, thirdParty: true };
    handleConsentChange(allTrue);
    setConsentState(allTrue);
    closeConsentDialog();
  };

  const handleDenyAll = () => {
    const allFalse = { functional: false, analytics: false, thirdParty: false };
    handleConsentChange(allFalse);
    setConsentState(allFalse);
    closeConsentDialog();
  };

  const theme = useTheme();

  return (
    <Dialog open={consentDialogOpen} onClose={closeConsentDialog} PaperProps={{ sx: { padding: theme.spacing(3), borderRadius: 3 } }}>
      <DialogTitle sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}>Cookie Consent</DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2 }}>
          We use cookies to enhance your experience. By agreeing to one of the options below, you consent to the corresponding usage of cookies.
        </Typography>
        {showCustomize && (
          <Box>
            <FormControlLabel
              control={<Checkbox checked={consentState.functional} onChange={handleChange} name="functional" />}
              label="Functional Cookies"
              sx={{ display: 'block', mb: 1 }}
            />
            <FormControlLabel
              control={<Checkbox checked={consentState.analytics} onChange={handleChange} name="analytics" />}
              label="Analytics Cookies"
              sx={{ display: 'block', mb: 1 }}
            />
            <FormControlLabel
              control={<Checkbox checked={consentState.thirdParty} onChange={handleChange} name="thirdParty" />}
              label="Third Party Cookies"
              sx={{ display: 'block', mb: 1 }}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {showCustomize ? (
          <>
            <Button onClick={handleSave} variant="contained" sx={{ mr: 2 }}>
              Save
            </Button>
            <Button onClick={() => setShowCustomize(false)} variant="outlined">
              Cancel
            </Button>
          </>
        ) : (
          <>
            <Button onClick={handleAcceptAll} variant="contained" sx={{ mr: 2 }}>
              Accept All
            </Button>
            <Button onClick={handleDenyAll} variant="outlined" sx={{ mr: 2 }}>
              Deny All
            </Button>
            <Button onClick={() => setShowCustomize(true)} variant="outlined">
              Customize
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CookieConsent;
