import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import { useAuth } from '../../authContext';
import Sidebar from '../../components/Sidebar';
import Profile from './Profile';
import Statistics from './ProfileStatistics';
import Admin from './Admin/Admin';
import Events from './Admin/AdminEvents';
import News from './Admin/AdminNews';
import Quotes from './Admin/AdminQuotes';
import Calls from './Admin/AdminCalls';

const UserDashboard = () => {
  const { currentUser, userRole } = useAuth();
  const topbarHeight = 64; // Adjust this value to match the height of your topbar

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar userRole={userRole} topbarHeight={topbarHeight} />
      <Box component="main" sx={{ flexGrow: 1, p: 3, mt: `${topbarHeight}px` }}>
        <Container maxWidth="md" sx={{ mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            User Dashboard
          </Typography>
          <Typography variant="h6" gutterBottom>
            Welcome to your personalized dashboard, {currentUser?.email}
          </Typography>
          {/* <Routes>
            <Route path="profile" element={<Profile />} />
            <Route path="statistics" element={<Statistics />} />
            <Route path="admin" element={<Admin />} />
            <Route path="admin/events" element={<Events />} />
            <Route path="admin/news" element={<News />} />
            <Route path="admin/quotes" element={<Quotes />} />
            <Route path="admin/calls" element={<Calls />} />
          </Routes> */}
        </Container>
      </Box>
    </Box>
  );
};

export default UserDashboard;