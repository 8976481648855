import React, { createContext, useContext, useState } from 'react';

const ConsentContext = createContext();

export const ConsentProvider = ({ children }) => {
  const [consent, setConsent] = useState(() => {
    const savedConsent = localStorage.getItem('consent');
    return savedConsent ? JSON.parse(savedConsent) : { functional: true, analytics: false, thirdParty: false };
  });
  const [consentDialogOpen, setConsentDialogOpen] = useState(false);

  const handleConsentChange = (newConsent) => {
    setConsent(newConsent);
    localStorage.setItem('consent', JSON.stringify(newConsent));
  };

  const openConsentDialog = () => setConsentDialogOpen(true);

  const closeConsentDialog = () => setConsentDialogOpen(false);

  return (
    <ConsentContext.Provider value={{ consent, handleConsentChange, openConsentDialog, closeConsentDialog, consentDialogOpen }}>
      {children}
    </ConsentContext.Provider>
  );
};

export const useConsent = () => {
  return useContext(ConsentContext);
};
