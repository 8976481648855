import React, { useState } from 'react';
import { Button, TextField, Typography, Container, Box } from '@mui/material';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LanguageSwitcher from './LanguageSwitcher';

const SignIn = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSignIn = async () => {
    const auth = getAuth();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/user-dashboard');
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Container maxWidth="sm" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '80vh' }}>
      <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
        <Typography variant="h4" gutterBottom>
          {t('signIn')}
        </Typography>
        <LanguageSwitcher />
      </Box>
      <TextField
        label={t('email')}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label={t('password')}
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        margin="normal"
      />
      {error && <Typography color="error">{error}</Typography>}
      <Button variant="contained" color="primary" onClick={handleSignIn} style={{ marginTop: '16px' }}>
        {t('signIn')}
      </Button>
      {/* <Typography variant="body2" style={{ marginTop: '16px' }}>
        {t('noAccount')} <Link component={RouterLink} to="/sign-up">{t('signUp')}</Link>
      </Typography> */}
    </Container>
  );
};

export default SignIn;
