import React, { useState, useRef, useEffect } from 'react';
import {
  Container, TextField, Button, Box, Card, CardContent, CardActions, Typography, Avatar,
  IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Alert, Checkbox, FormControlLabel
} from '@mui/material';
import { Delete, ZoomIn, ZoomOut, Edit, Save } from '@mui/icons-material';
import { collection, addDoc, getDocs, doc, updateDoc, deleteDoc, getDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAuth } from '../../../authContext';
import { firestore } from '../../../firebase';
import AvatarEditor from 'react-avatar-editor';

const ContactsPage = () => {
    const [contacts, setContacts] = useState([]);
    const [contactName, setContactName] = useState('');
    const [role, setRole] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [contactPhone, setContactPhone] = useState('');
    const [contactImage, setContactImage] = useState(null);
    const [contactImageUrl, setContactImageUrl] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [currentContactId, setCurrentContactId] = useState(null);
    const [zoom, setZoom] = useState(1);
    const [openDialog, setOpenDialog] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteContactId, setDeleteContactId] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const editorRef = useRef(null);
    const { currentUser } = useAuth();
    const [isAdmin, setIsAdmin] = useState(false);
    const [isBoardMember, setIsBoardMember] = useState(false);
    const [isAdvisoryBoard, setIsAdvisoryBoard] = useState(false);
    const [isSafePerson, setIsSafePerson] = useState(false);

  useEffect(() => {
    const checkAdminRole = async () => {
      if (currentUser) {
        const roleDoc = await getDoc(doc(firestore, 'roles', currentUser.uid));
        if (roleDoc.exists() && roleDoc.data().roles.includes('admin')) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      }
    };

    const fetchContacts = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'contacts'));
      const contactsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setContacts(contactsData);
    };

    checkAdminRole();
    fetchContacts();
  }, [currentUser]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setContactImage(file);
    } else {
      setOpenSnackbar(true);
      setErrorMessage('Invalid file type. Please select an image.');
    }
  };

  const handleImageUpload = async (image, editorRef) => {
    if (!image) return '';
    const storage = getStorage();
    const uniqueName = `${Date.now()}-${image.name}`;
    const storageRef = ref(storage, `contact-images/${uniqueName}`);
    let blob;
    if (editorRef) {
      const canvas = editorRef.current.getImageScaledToCanvas().toDataURL();
      blob = await (await fetch(canvas)).blob();
    } else {
      blob = image;
    }
    await uploadBytes(storageRef, blob);
    const url = await getDownloadURL(storageRef);
    setContactImageUrl(url);
    return url;
  };

  const handleDeleteImage = () => {
    setContactImage(null);
  };

  const validateForm = () => {
    if (!contactName || !role || !contactEmail || !contactPhone || !contactImageUrl) {
      setOpenSnackbar(true);
      setErrorMessage('All fields are required');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
  
    try {
      const imageUrl = contactImage ? await handleImageUpload(contactImage, editorRef) : contactImageUrl;
  
      if (currentContactId) {
        const contactRef = doc(firestore, 'contacts', currentContactId);
        await updateDoc(contactRef, {
          name: contactName,
          role,
          email: contactEmail,
          phone: contactPhone,
          imageUrl,
          isBoardMember,
          isAdvisoryBoard,
          isSafePerson
        });
        setContacts(contacts.map(contact => contact.id === currentContactId ? { ...contact, name: contactName, role, email: contactEmail, phone: contactPhone, imageUrl, isBoardMember, isAdvisoryBoard, isSafePerson } : contact));
      } else {
        const newDoc = await addDoc(collection(firestore, 'contacts'), {
          name: contactName,
          role,
          email: contactEmail,
          phone: contactPhone,
          imageUrl,
          isBoardMember,
          isAdvisoryBoard,
          isSafePerson
        });
        setContacts([...contacts, { id: newDoc.id, name: contactName, role, email: contactEmail, phone: contactPhone, imageUrl, isBoardMember, isAdvisoryBoard, isSafePerson }]);
      }
  
      handleCloseDialog();
    } catch (err) {
      console.error('Error creating/updating contact:', err);
      setOpenSnackbar(true);
      setErrorMessage('Error creating/updating contact. Please try again.');
    }
  };
  

  const handleEdit = (contact) => {
    setContactName(contact.name);
    setRole(contact.role);
    setContactEmail(contact.email);
    setContactPhone(contact.phone);
    setContactImageUrl(contact.imageUrl);
    setIsBoardMember(contact.isBoardMember || false);
    setIsAdvisoryBoard(contact.isAdvisoryBoard || false);
    setIsSafePerson(contact.isSafePerson || false);
    setCurrentContactId(contact.id);
    setEditMode(true);
    setOpenDialog(true);
  };
  

  const handleOpenDeleteDialog = (id) => {
    setDeleteContactId(id);
    setDeleteDialogOpen(true);
  };

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(firestore, 'contacts', deleteContactId));
      setContacts(contacts.filter(contact => contact.id !== deleteContactId));
      setDeleteDialogOpen(false);
    } catch (err) {
      console.error('Error deleting contact:', err);
      setOpenSnackbar(true);
      setErrorMessage('Error deleting contact. Please try again.');
    }
  };

  const handleOpenDialog = () => {
    setContactName('');
    setRole('');
    setContactEmail('');
    setContactPhone('');
    setContactImage(null);
    setContactImageUrl('');
    setIsBoardMember(false);
    setIsAdvisoryBoard(false);
    setIsSafePerson(false);
    setCurrentContactId(null);
    setEditMode(false);
    setOpenDialog(true);
  };
  
  const handleCloseDialog = () => {
    setContactName('');
    setRole('');
    setContactEmail('');
    setContactPhone('');
    setContactImage(null);
    setContactImageUrl('');
    setIsBoardMember(false);
    setIsSafePerson(false);
    setIsAdvisoryBoard(false);
    setCurrentContactId(null);
    setEditMode(false);
    setOpenDialog(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  if (!isAdmin) {
    return <Typography variant="h6">Access Denied</Typography>;
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>Manage Contacts</Typography>
      <Button variant="contained" color="primary" onClick={handleOpenDialog}>
        Add Contact
      </Button>
      <Box sx={{ mt: 2 }}>
        {contacts.map(contact => (
          <Card key={contact.id} sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="h6">{contact.name}</Typography>
              <Avatar src={contact.imageUrl} sx={{ width: 56, height: 56 }} />
              <Typography variant="body2">{contact.role}</Typography>
              <Typography variant="body2">{contact.email}</Typography>
              <Typography variant="body2">{contact.phone}</Typography>
            </CardContent>
            <CardActions>
              <IconButton onClick={() => handleEdit(contact)}>
                <Edit />
              </IconButton>
              <IconButton onClick={() => handleOpenDeleteDialog(contact.id)}>
                <Delete />
              </IconButton>
            </CardActions>
          </Card>
        ))}
      </Box>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{editMode ? 'Edit Contact' : 'Create Contact'}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Contact Name"
              variant="outlined"
              fullWidth
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Role"
              variant="outlined"
              fullWidth
              value={role}
              onChange={(e) => setRole(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Contact Email"
              variant="outlined"
              fullWidth
              value={contactEmail}
              onChange={(e) => setContactEmail(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Contact Phone"
              variant="outlined"
              fullWidth
              value={contactPhone}
              onChange={(e) => setContactPhone(e.target.value)}
              sx={{ mb: 2 }}
            />
            <Box mb={2}>
                <FormControlLabel
                    control={
                    <Checkbox
                        checked={isBoardMember}
                        onChange={(e) => setIsBoardMember(e.target.checked)}
                        name="boardMember"
                    />
                    }
                    label="Board Member"
                />
                <FormControlLabel
                    control={
                    <Checkbox
                        checked={isAdvisoryBoard}
                        onChange={(e) => setIsAdvisoryBoard(e.target.checked)}
                        name="advisoryBoard"
                    />
                    }
                    label="Advisory Board"
                />
                <FormControlLabel
                    control={
                    <Checkbox
                        checked={isSafePerson}
                        onChange={(e) => setIsSafePerson(e.target.checked)}
                        name="safePerson"
                    />
                    }
                    label="Safe Person"
                />
                </Box>

            <Box mb={2}>
              <Typography variant="h6">Upload Contact Image</Typography>
              <Button variant="outlined" component="label">
                Upload Image
                <input type="file" accept="image/*" hidden onChange={handleFileChange} />
              </Button>
              {contactImage && (
                <Box mt={2} textAlign="center">
                  <AvatarEditor
                    ref={editorRef}
                    image={contactImage}
                    width={150}
                    height={150}
                    border={50}
                    borderRadius={75}
                    scale={zoom}
                    rotate={0}
                    style={{ width: '100%', height: 'auto' }}
                  />
                  <Box mt={2} display="flex" justifyContent="center">
                    <IconButton onClick={() => setZoom(zoom + 0.1)}>
                      <ZoomIn />
                    </IconButton>
                    <IconButton onClick={() => setZoom(zoom - 0.1)}>
                      <ZoomOut />
                    </IconButton>
                    <IconButton onClick={() => handleImageUpload(contactImage, editorRef)}>
                      <Save />
                    </IconButton>
                    <IconButton onClick={handleDeleteImage}>
                      <Delete />
                    </IconButton>
                  </Box>
                </Box>
              )}
            </Box>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
              <Button type="submit" color="primary">{editMode ? 'Save' : 'Create'}</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this contact?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">Cancel</Button>
          <Button onClick={handleDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ContactsPage;
