// src/components/Footer.js
import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const Footer = () => {
  return (
    <footer>
      <Box py={3} bgcolor="lightgrey" textAlign="center">
        <Typography variant="body2">
          &copy; {new Date().getFullYear()} European Youth Parliament Austria. All rights reserved.
        </Typography>
        <Typography variant="body2">
          <Link href="/impressum">Impressum</Link> | <Link href="/privacy">Data Privacy Policy</Link>
        </Typography>
      </Box>
    </footer>
  );
};

export default Footer;
