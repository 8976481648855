import React, { useState, useEffect } from 'react';
import { Container, Typography, Card, CardContent, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { firestore } from '../firebase';
import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';

const EventsPage = () => {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [openCalls, setOpenCalls] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEventsAndCalls = async () => {
      try {
        const eventsCollection = collection(firestore, 'events');
        const callsCollection = collection(firestore, 'calls');
        const now = Timestamp.fromDate(new Date());
        const upcomingQuery = query(eventsCollection, where('endDate', '>=', now));
        const pastQuery = query(eventsCollection, where('endDate', '<', now));
        const callsQuery = query(callsCollection, where('endDate', '>=', now)); // Only open calls

        const [upcomingSnapshot, pastSnapshot, callsSnapshot] = await Promise.all([
          getDocs(upcomingQuery),
          getDocs(pastQuery),
          getDocs(callsQuery),
        ]);

        setUpcomingEvents(upcomingSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        setPastEvents(pastSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        setOpenCalls(callsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (err) {
        console.error('Error fetching events and calls:', err);
        setError(err.message);
      }
    };

    fetchEventsAndCalls();
  }, []);

  const renderEventCard = (event) => (
    <Card key={event.id} sx={{ mb: 4 }} elevation={2}>
      <CardContent>
        <Typography variant="h6" component={RouterLink} to={`/events/${event.id}`}>
          {event.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {new Date(event.startDate.toDate()).toLocaleDateString()} - {new Date(event.endDate.toDate()).toLocaleDateString()}
        </Typography>
        <Button component={RouterLink} to={`/events/${event.id}`} variant="contained" sx={{ mt: 2 }}>
          Learn more
        </Button>
      </CardContent>
    </Card>
  );

  const renderCallCard = (call) => (
    <Card key={call.id} sx={{ mb: 4 }} elevation={2}>
      <CardContent>
        <Typography variant="h6">
          {call.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {call.description}
        </Typography>
        <Button component="a" href={call.applyLink} target="_blank" rel="noopener noreferrer" variant="contained" sx={{ mt: 2 }}>
          Download Application
        </Button>
      </CardContent>
    </Card>
  );

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>Upcoming Events</Typography>
      {upcomingEvents.length > 0 ? upcomingEvents.map(renderEventCard) : (
        <Typography variant="body1">There are no upcoming events.</Typography>
      )}

      <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>Open Calls</Typography>
      {openCalls.length > 0 ? openCalls.map(renderCallCard) : (
        <Typography variant="body1">There are no open calls at the moment.</Typography>
      )}

      <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>Past Events</Typography>
      {pastEvents.length > 0 ? pastEvents.map(renderEventCard) : (
        <Typography variant="body1">There are no past events.</Typography>
      )}
    </Container>
  );
};

export default EventsPage;
