import React, { useState } from 'react';
import { TextField, Button, Typography, Container, Link } from '@mui/material';
import { auth } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSignUp = async () => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      alert('Signed up successfully');
      navigate('/user-dashboard');
    } catch (error) {
      console.error('Error signing up:', error);
      alert(error.message);
    }
  };

  return (
    <Container>
      <Typography variant="h4">Sign Up</Typography>
      <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} fullWidth margin="normal" />
      <TextField label="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} fullWidth margin="normal" />
      <Button variant="contained" color="primary" onClick={handleSignUp}>Sign Up</Button>
      <Typography variant="body2" style={{ marginTop: '16px' }}>
        {('alreadyAccount')} <Link component={RouterLink} to="/sign-in">{('signIn')}</Link>
      </Typography>
    </Container>
  );
}

export default SignUp;
