import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Container, CssBaseline, createTheme, ThemeProvider, Box } from '@mui/material';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import NewsPage from './pages/NewsPage';
import EventsPage from './pages/EventsPage';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import PrivateRoute from './components/PrivateRoute';
import Footer from './components/Footer';
import UserDashboard from './pages/LoggedIn/UserDashboard';
import { AuthProvider } from './authContext';
import AdminRoute from './components/AdminRoute';
import EventDetailsPage from './pages/EventDetailsPage';
import CreateEventForm from './components/CreateEventForm';
import SearchResultsPage from './pages/SearchResultsPage';
import PartnerPage from './pages/PartnerPage';
import AboutUsPage from './pages/AboutUsPage';
import ContactPage from './pages/ContactPage';
import CreateContactForm from './components/CreateContactForm';
import MemberPage from './pages/MemberPage';
import CookieConsent from './components/CookieConsent';
import Impressum from './pages/ImpressumPage';
import PrivacyPolicy from './pages/PrivacyPolicyPage';
import AdminDashboard from './pages/LoggedIn/Admin/Admin';
import AdminEvents from './pages/LoggedIn/Admin/AdminEvents';
import AdminNews from './pages/LoggedIn/Admin/AdminNews';
import AdminQuotes from './pages/LoggedIn/Admin/AdminQuotes';
import AdminCalls from './pages/LoggedIn/Admin/AdminCalls';
import ErrorPage from './components/ErrorPage';
import DashboardLayout from './components/DashboardLayout';
import AdminContacts from './pages/LoggedIn/Admin/AdminContacts';

function App() {
  const [darkMode, setDarkMode] = useState(false);

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
  });

  const topbarHeight = 64; // Adjust based on actual topbar height

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Navbar darkMode={darkMode} setDarkMode={setDarkMode} />
        <Routes>
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/events" element={<EventsPage />} />
          <Route path="/events/:id" element={<EventDetailsPage />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/search" element={<SearchResultsPage />} />
          <Route path="/partner" element={<PartnerPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/membership" element={<MemberPage />} />
          <Route path="/user-dashboard/*" element={<PrivateRoute><DashboardLayout topbarHeight={topbarHeight}><UserDashboard /></DashboardLayout></PrivateRoute>} />
          <Route path="/user-dashboard/admin" element={<AdminRoute><DashboardLayout topbarHeight={topbarHeight}><AdminDashboard /></DashboardLayout></AdminRoute>} />
          <Route path="/user-dashboard/admin/events" element={<AdminRoute><DashboardLayout topbarHeight={topbarHeight}><AdminEvents /></DashboardLayout></AdminRoute>} />
          <Route path="/user-dashboard/admin/news" element={<AdminRoute><DashboardLayout topbarHeight={topbarHeight}><AdminNews /></DashboardLayout></AdminRoute>} />
          <Route path="/user-dashboard/admin/quotes" element={<AdminRoute><DashboardLayout topbarHeight={topbarHeight}><AdminQuotes /></DashboardLayout></AdminRoute>} />
          <Route path="/user-dashboard/admin/calls" element={<AdminRoute><DashboardLayout topbarHeight={topbarHeight}><AdminCalls /></DashboardLayout></AdminRoute>} />
          <Route path="/user-dashboard/admin/contacts" element={<AdminRoute><DashboardLayout topbarHeight={topbarHeight}><AdminContacts /></DashboardLayout></AdminRoute>} />
          <Route path="/create-event" element={<AdminRoute><DashboardLayout topbarHeight={topbarHeight}><CreateEventForm /></DashboardLayout></AdminRoute>} />
          <Route path="/create-contacts" element={<AdminRoute><DashboardLayout topbarHeight={topbarHeight}><CreateContactForm /></DashboardLayout></AdminRoute>} />
          <Route path="*" element={<ErrorPage message="This page does not exist or you do not have the right permissions." />} />
        </Routes>
        <Footer />
        <CookieConsent />
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
