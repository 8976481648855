import React from 'react';
import { Box, List, ListItem, ListItemText, ListItemIcon, Drawer } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import BarChartIcon from '@mui/icons-material/BarChart';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import EventIcon from '@mui/icons-material/Event';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import CallIcon from '@mui/icons-material/Call';
import { useAuth } from '../authContext';

const Sidebar = ({ topbarHeight }) => {
  const navigate = useNavigate();
  const { userRoles } = useAuth();

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 240,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: 240,
          boxSizing: 'border-box',
          marginTop: `${topbarHeight+8}px`, // Start below the topbar
          marginBottom: `${topbarHeight+8}px`, // Start below the topbar
          height: `calc(100% - 7.9vw)`, // Adjust height to not overlap with footer
        }
      }}
    >
      <Box sx={{ overflow: 'auto' }}>
        <List>
          <ListItem button onClick={() => navigate('/user-dashboard/')}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button onClick={() => navigate('/user-dashboard/profile')}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItem>
          <ListItem button onClick={() => navigate('/user-dashboard/statistics')}>
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Statistics" />
          </ListItem>
          {userRoles.includes('admin') && (
            <>
              <ListItem button onClick={() => navigate('/user-dashboard/admin')}>
                <ListItemIcon>
                  <AdminPanelSettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Admin" />
              </ListItem>
              <List component="div" disablePadding>
                <ListItem button onClick={() => navigate('/user-dashboard/admin/events')} sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <EventIcon />
                  </ListItemIcon>
                  <ListItemText primary="Events" />
                </ListItem>
                <ListItem button onClick={() => navigate('/user-dashboard/admin/news')} sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <NewspaperIcon />
                  </ListItemIcon>
                  <ListItemText primary="News" />
                </ListItem>
                <ListItem button onClick={() => navigate('/user-dashboard/admin/quotes')} sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <FormatQuoteIcon />
                  </ListItemIcon>
                  <ListItemText primary="Quotes" />
                </ListItem>
                <ListItem button onClick={() => navigate('/user-dashboard/admin/calls')} sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <CallIcon />
                  </ListItemIcon>
                  <ListItemText primary="Calls" />
                </ListItem>
                <ListItem button onClick={() => navigate('/user-dashboard/admin/contacts')} sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary="Contacts" />
                </ListItem>
              </List>
            </>
          )}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
