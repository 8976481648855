import { initializeApp } from 'firebase/app';
import { getAuth, signOut } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAsSVTihQnY8xpZUgMMxgE3JJHskkAx9Ug",
  authDomain: "eyp-at.firebaseapp.com",
  projectId: "eyp-at",
  storageBucket: "eyp-at.appspot.com",
  messagingSenderId: "612866535424",
  appId: "1:612866535424:web:f4a9dd877ba67fc12368e5",
  measurementId: "G-3KJS6FDMRR"
};


const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);

export { app, firestore, auth, signOut };