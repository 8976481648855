import React, { useState, useEffect } from 'react';
import {
  Container, TextField, Button, Box, Typography, Snackbar, Alert,
  IconButton, Card, CardContent, CardActions, Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import { collection, addDoc, getDocs, doc, deleteDoc, updateDoc, getDoc, Timestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { firestore } from '../../../firebase';
import { useAuth } from '../../../authContext';

const AdminCalls = () => {
  const [calls, setCalls] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [endDate, setEndDate] = useState('');
  const [document, setDocument] = useState(null);
  const [currentCallId, setCurrentCallId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteCallId, setDeleteCallId] = useState(null);
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { currentUser } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAdminRole = async () => {
      if (currentUser) {
        const roleDoc = await getDoc(doc(firestore, 'roles', currentUser.uid));
        if (roleDoc.exists() && roleDoc.data().roles.includes('admin')) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      }
    };

    const fetchCalls = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'calls'));
      const callsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCalls(callsData);
    };

    checkAdminRole();
    fetchCalls();
  }, [currentUser]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setDocument(file);
    }
  };

  const uploadDocument = async () => {
    if (!document) return '';
    const storage = getStorage();
    const storageRef = ref(storage, `call-documents/${document.name}`);
    try {
      await uploadBytes(storageRef, document);
      const url = await getDownloadURL(storageRef);
      return url;
    } catch (err) {
      setError('Error uploading document. Please check your permissions.');
      setOpenSnackbar(true);
      throw err;
    }
  };

  const validateForm = () => {
    if (!title || !description || !endDate || (!document && !currentCallId)) {
      setError('All fields are required');
      setOpenSnackbar(true);
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      let docUrl = '';
      if (document) {
        docUrl = await uploadDocument();
      }

      const endDateTimestamp = Timestamp.fromDate(new Date(endDate));

      if (currentCallId) {
        const callRef = doc(firestore, 'calls', currentCallId);
        await updateDoc(callRef, {
          title,
          description,
          endDate: endDateTimestamp,
          ...(docUrl && { applyLink: docUrl }),
        });
        setCalls(calls.map(call => call.id === currentCallId ? { ...call, title, description, endDate: endDateTimestamp, ...(docUrl && { applyLink: docUrl }) } : call));
      } else {
        const newDoc = await addDoc(collection(firestore, 'calls'), {
          title,
          description,
          endDate: endDateTimestamp,
          applyLink: docUrl,
        });
        setCalls([...calls, { id: newDoc.id, title, description, endDate: endDateTimestamp, applyLink: docUrl }]);
      }

      handleCloseDialog();
    } catch (err) {
      console.error('Error creating/updating call:', err);
      setError('Error creating/updating call. Please try again.');
      setOpenSnackbar(true);
    }
  };

  const handleEdit = (call) => {
    setTitle(call.title);
    setDescription(call.description);
    setEndDate(call.endDate.toDate().toISOString().split('T')[0]);
    setCurrentCallId(call.id);
    setOpenDialog(true);
  };

  const handleOpenDeleteDialog = (id) => {
    setDeleteCallId(id);
    setDeleteDialogOpen(true);
  };

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(firestore, 'calls', deleteCallId));
      setCalls(calls.filter(call => call.id !== deleteCallId));
      setDeleteDialogOpen(false);
    } catch (err) {
      console.error('Error deleting call:', err);
      setError('Error deleting call. Please try again.');
      setOpenSnackbar(true);
    }
  };

  const handleOpenDialog = () => {
    setTitle('');
    setDescription('');
    setEndDate('');
    setDocument(null);
    setCurrentCallId(null);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setTitle('');
    setDescription('');
    setEndDate('');
    setDocument(null);
    setCurrentCallId(null);
    setOpenDialog(false);
  };

  if (!isAdmin) {
    return <Typography variant="h6">Access Denied</Typography>;
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>Manage Calls</Typography>
      <IconButton onClick={handleOpenDialog}>
        <Add fontSize="large" />
      </IconButton>
      <Box sx={{ mt: 2 }}>
        {calls.map(call => (
          <Card key={call.id} sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="h6">{call.title}</Typography>
              <Typography variant="body2">{call.description}</Typography>
              <Typography variant="body2">Open until: {new Date(call.endDate.toDate()).toLocaleDateString()}</Typography>
              <Button href={call.applyLink} target="_blank" variant="contained" sx={{ mt: 2 }}>Apply</Button>
            </CardContent>
            <CardActions>
              <IconButton onClick={() => handleEdit(call)}>
                <Edit />
              </IconButton>
              <IconButton onClick={() => handleOpenDeleteDialog(call.id)}>
                <Delete />
              </IconButton>
            </CardActions>
          </Card>
        ))}
      </Box>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{currentCallId ? 'Edit Call' : 'Create Call'}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Call Title"
              variant="outlined"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="End Date"
              type="date"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              sx={{ mb: 2 }}
            />
            {!currentCallId && (
              <Box mb={2}>
                <Typography variant="h6">Upload Application Document</Typography>
                <Button variant="outlined" component="label">
                  Upload Document
                  <input type="file" accept=".pdf,.doc,.docx" hidden onChange={handleFileChange} />
                </Button>
                {document && (
                  <Box mt={2} textAlign="center">
                    <Typography variant="body1">{document.name}</Typography>
                  </Box>
                )}
              </Box>
            )}
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
              <Button type="submit" color="primary">{currentCallId ? 'Save' : 'Create'}</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this call?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">Cancel</Button>
          <Button onClick={handleDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AdminCalls;
