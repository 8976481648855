// src/components/LanguageSwitcher.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { useTheme } from '@mui/material/styles';
import enFlag from '../assets/en.svg';
import deFlag from '../assets/de.svg';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const theme = useTheme();

  const options = [
    { value: 'en', label: <img src={enFlag} alt="English" style={{ width: 20, height: 15 }} />, text: 'English' },
    { value: 'de', label: <img src={deFlag} alt="Deutsch" style={{ width: 20, height: 15 }} />, text: 'Deutsch' },
  ];

  const handleChange = (selectedOption) => {
    i18n.changeLanguage(selectedOption.value);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: 150,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    }),
    singleValue: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.primary,
    }),
    option: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.primary,
    }),
  };

  return (
    <Select
      options={options}
      onChange={handleChange}
      styles={customStyles}
      defaultValue={options.find(option => option.value === i18n.language)}
      formatOptionLabel={(option) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {option.label}
          <span style={{ marginLeft: 8 }}>{option.text}</span>
        </div>
      )}
    />
  );
};

export default LanguageSwitcher;
