import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, signOut as firebaseSignOut } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();

    const fetchUserRoles = async (user) => {
      if (user) {
        const roleDoc = await getDoc(doc(db, 'roles', user.uid));
        if (roleDoc.exists()) {
          setUserRoles(roleDoc.data().roles || []);
        } else {
          setUserRoles([]);
        }
      } else {
        setUserRoles([]);
      }
      setLoading(false);
    };

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      await fetchUserRoles(user);
    });

    return unsubscribe;
  }, []);

  const signOut = () => {
    const auth = getAuth();
    firebaseSignOut(auth);
  };

  return (
    <AuthContext.Provider value={{ currentUser, userRoles, signOut }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
