import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, Box, Link, Paper, CircularProgress, Pagination, CardMedia, Card, Button } from '@mui/material';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { InstagramEmbed } from 'react-social-media-embed';
import { useConsent } from '../context/ConsentContext'; 

const PartnersPage = () => {
  const { consent, openConsentDialog } = useConsent();  // Add openConsentDialog

  const [partners, setPartners] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [marketingNumbers, setMarketingNumbers] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 12;

  useEffect(() => {
    const fetchPartners = async () => {
      const db = getFirestore();
      const partnersSnapshot = await getDocs(collection(db, 'partners'));
      const partnersData = partnersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setPartners(partnersData);
    };


    const fetchGalleryImages = async () => {
      const galleryData = [
        { id: 1, url: 'https://via.placeholder.com/300', alt: 'Partner Image 1' },
        { id: 2, url: 'https://via.placeholder.com/300', alt: 'Partner Image 2' },
        { id: 3, url: 'https://via.placeholder.com/300', alt: 'Partner Image 3' },
        { id: 4, url: 'https://via.placeholder.com/300', alt: 'Partner Image 4' },
        { id: 5, url: 'https://via.placeholder.com/300', alt: 'Partner Image 5' },
        { id: 6, url: 'https://via.placeholder.com/300', alt: 'Partner Image 6' },
        { id: 7, url: 'https://via.placeholder.com/300', alt: 'Partner Image 7' },
        { id: 8, url: 'https://via.placeholder.com/300', alt: 'Partner Image 8' },
        { id: 9, url: 'https://via.placeholder.com/300', alt: 'Partner Image 9' },
      ];
      setGalleryImages(galleryData);
    };

    const fetchMarketingNumbers = async () => {
      const marketingData = {
        instagram: 1746,
        facebook: 4541,
        twitter: 25126,
        outreach: 50000,
      };
      setMarketingNumbers(marketingData);
    };

    fetchPartners();
    fetchGalleryImages();
    fetchMarketingNumbers();
  }, []);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const displayedImages = galleryImages.slice(
    (currentPage - 1) * imagesPerPage,
    currentPage * imagesPerPage
  );

  return (
    <Container maxWidth="lg">
      <Box textAlign="center" py={5}>
        <Typography variant="h3" gutterBottom>
          Our Partners
        </Typography>
        <Typography variant="h6" color="textSecondary" gutterBottom>
          We are grateful for the support of our esteemed partners.
        </Typography>
      </Box>
      <Grid container spacing={4} justifyContent="center">
        {partners.map((partner) => (
          <Grid item xs={12} sm={6} md={4} key={partner.id}>
            <Paper
              elevation={3}
              sx={{
                textAlign: 'center',
                padding: 4,
                borderRadius: 2,
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
            >
              <CardMedia
                component="img"
                height="200"
                image={partner.logoUrl}
                alt={partner.name}
                sx={{ objectFit: 'contain', margin: '0 auto', mb: 2 }}
              />
              <Typography variant="h5" gutterBottom>
                {partner.name}
              </Typography>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                <Link href={partner.website} target="_blank" rel="noopener" sx={{ textDecoration: 'none', color: 'primary.main' }}>
                  {partner.website}
                </Link>
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Box mt={8} textAlign="center">
        <Typography variant="h4" gutterBottom>
          EYP in Marketing Numbers
        </Typography>
        <Typography variant="h6" color="textSecondary" gutterBottom>
          Our social media presence and outreach statistics.
        </Typography>
        <Grid container spacing={4} justifyContent="center" mt={4}>
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ padding: 4, textAlign: 'center' }}>
              <Typography variant="h5">Instagram</Typography>
              <Typography variant="h4" color="primary">{marketingNumbers.instagram}</Typography>
              <Typography variant="body1">Followers</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ padding: 4, textAlign: 'center' }}>
              <Typography variant="h5">Facebook</Typography>
              <Typography variant="h4" color="primary">{marketingNumbers.facebook}</Typography>
              <Typography variant="body1">Accounts Reached</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ padding: 4, textAlign: 'center' }}>
              <Typography variant="h5">TikTok</Typography>
              <Typography variant="h4" color="primary">{marketingNumbers.twitter}</Typography>
              <Typography variant="body1">Accounts reached</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ padding: 4, textAlign: 'center' }}>
              <Typography variant="h5">Outreach</Typography>
              <Typography variant="h4" color="primary">{marketingNumbers.outreach}</Typography>
              <Typography variant="body1">People Reached</Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Box mt={8} textAlign="center">
        <Typography variant="h4" gutterBottom>
          Partner Gallery
        </Typography>
        <Typography variant="h6" color="textSecondary" gutterBottom>
          A showcase of our partners in action.
        </Typography>
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              {displayedImages.map((image) => (
                <Grid item xs={6} sm={4} md={3} key={image.id}>
                  <Card
                    sx={{
                      boxShadow: 3,
                      borderRadius: 2,
                      overflow: 'hidden',
                    }}
                  >
                    <CardMedia component="img" image={image.url} alt={image.alt} sx={{ width: '100%', height: 'auto' }} />
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Box mt={2}>
              <Pagination
                count={Math.ceil(galleryImages.length / imagesPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ width: '100%', maxWidth: '400px', mx: 'auto' }}>
              {consent.thirdParty ? (
                <InstagramEmbed
                  url="https://www.instagram.com/p/C7WeHC4N2Ta/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                  width="100%"
                  hideCaption={false}
                  containerTagName="div"
                  protocol=""
                  injectScript
                  onLoading={() => <CircularProgress />}
                  onSuccess={() => console.log('Loaded')}
                  onAfterRender={() => console.log('Rendered')}
                  onFailure={(error) => console.error('Error:', error)}
                />
              ) : (
                <Box>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    Instagram content is hidden until you accept third-party cookies.
                  </Typography>
                  <Button variant="contained" onClick={openConsentDialog}>
                    Customize Cookie Settings
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* {financeBoardMember && (
        <Box mt={8} textAlign="center">
          <Typography variant="h4" gutterBottom>
            Want to Partner with Us?
          </Typography>
          <Typography variant="h6" color="textSecondary" gutterBottom>
            Contact our Finance Board Member to discuss partnership opportunities.
          </Typography>
          <Grid container justifyContent="center" mt={4}>
            <Grid item xs={12} sm={6} md={4}>
              {renderContactCard(financeBoardMember)}
            </Grid>
          </Grid>
        </Box>
      )} */}
    </Container>
  );
};

export default PartnersPage;
