import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  IconButton,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemText,
  InputBase,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../authContext';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';
import logo from '../assets/logoMain.png'; // Add your logo path as PNG
import { alpha, styled } from '@mui/material/styles';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

function Navbar({ darkMode, setDarkMode }) {
  const { currentUser, signOut } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSignOut = () => {
    signOut();
    navigate('/');
  };

  const handleReturnToMain = () => {
    navigate('/');
  };

  const handleUserDashboard = () => {
    navigate('/user-dashboard');
  };

  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/search?query=${searchQuery}`);
  };

  const menuItems = [
    { label: t('aboutUs'), path: '/about-us' },
    { label: t('news'), path: '/news' },
    { label: t('events'), path: '/events' },
    { label: t('partner'), path: '/partner' },
    { label: t('membership'), path: '/membership' },
    { label: t('contact'), path: '/contact' },
  ];

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <List>
        {menuItems.map((item, index) => (
          <ListItem button component={RouterLink} to={item.path} key={index}>
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar position="static" sx={{ backgroundColor: '#fff', color: '#000' }}>
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={handleReturnToMain}>
          <img src={logo} alt="Logo" style={{ width: '13vw', height: 'auto', padding: '0' }} />
        </IconButton>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
          {menuItems.map((item, index) => (
            <Button color="inherit" component={RouterLink} to={item.path} key={index} sx={{ color: '#000', padding: '1vw', textTransform: 'none', fontWeight: 'light', fontSize: '1vw' }}>
              {item.label}
            </Button>
          ))}
        </Box>
        {isMobile ? (
          <>
            <IconButton color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="right" open={mobileOpen} onClose={handleDrawerToggle}>
              {drawer}
            </Drawer>
          </>
        ) : (
          <>
            <Search>
              <form onSubmit={handleSearch}>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ 'aria-label': 'search' }}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </form>
            </Search>
            {currentUser ? (
              <>
                <IconButton color="inherit" onClick={handleUserDashboard}>
                  <AccountCircle />
                </IconButton>
                <Button color="inherit" onClick={handleSignOut} sx={{ color: '#000', textTransform: 'none' }}>
                  {t('signOut')}
                </Button>
              </>
            ) : (null)
              // <IconButton color="inherit" component={RouterLink} to="/sign-in">
              //   <LoginIcon />
              // </IconButton>
            }
            {/* <LanguageSwitcher /> */}
            {/* <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
              <Switch checked={darkMode} onChange={() => setDarkMode(!darkMode)} />
            </Box> */}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
