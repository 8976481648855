import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, Card, CardContent, Box } from '@mui/material';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import Pagination from '@mui/material/Pagination';

const NewsPage = () => {
  const [newsArticles, setNewsArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 6;

  useEffect(() => {
    const fetchNewsArticles = async () => {
      const db = getFirestore();
      const newsSnapshot = await getDocs(collection(db, 'news'));
      const newsData = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setNewsArticles(newsData);
    };

    fetchNewsArticles();
  }, []);

  // Pagination logic
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = newsArticles.slice(indexOfFirstArticle, indexOfLastArticle);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Container maxWidth="lg">
      <Box textAlign="center" py={5} sx={{ backgroundColor: '#f4f4f9', borderRadius: '8px' }}>
        <Typography variant="h3" gutterBottom sx={{ color: '#003366' }}>
          Latest News
        </Typography>
        <Typography variant="h6" color="textSecondary" gutterBottom>
          Stay updated with the latest news and articles.
        </Typography>
      </Box>
      <Grid container spacing={4} sx={{ mt: 3 }}>
        {currentArticles.map((article) => (
          <Grid item xs={12} md={6} lg={4} key={article.id}>
            <Card
              sx={{
                boxShadow: 3,
                borderRadius: 2,
                overflow: 'hidden',
                transition: 'transform 0.3s ease-in-out',
                backgroundColor: '#ffffff',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
            >
              <CardContent>
                <Typography variant="h5" gutterBottom sx={{ color: '#003366' }}>
                  {article.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" paragraph>
                  {article.content.length > 100
                    ? `${article.content.substring(0, 100)}...`
                    : article.content}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box mt={4} display="flex" justifyContent="center">
        <Pagination
          count={Math.ceil(newsArticles.length / articlesPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Container>
  );
};

export default NewsPage;
