import React, { useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Container, Grid, Box, Typography, Button, Card, CardMedia, CardContent, IconButton, Paper, Avatar, Divider, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getFirestore, collection, query, getDocs, Timestamp, where, orderBy, limit, includes } from "firebase/firestore"; 
import { InstagramEmbed } from 'react-social-media-embed';
import { useNavigate } from 'react-router-dom';
import { useConsent } from '../context/ConsentContext'; 

import homeImage1 from '../assets/Home/first.jpg'; // Add your image paths
import homeImage2 from '../assets/Home/second.jpg'; // Add your image paths
import homeImage3 from '../assets/Home/third.jpg'; // Add your image paths
import homeImage4 from '../assets/Home/fourth.png'; // Add your image paths
import homeImage5 from '../assets/Home/fifth.jpg'; // Add your image paths

import avatar1 from '../assets/Home/avatar1.jpg'; // Add your avatar paths
import avatar2 from '../assets/Home/avatar2.jpg'; // Add your avatar paths
import avatar3 from '../assets/Home/avatar3.jpg'; // Add your avatar paths
import avatar4 from '../assets/Home/avatar4.jpg'; // Add your avatar paths
import avatar5 from '../assets/Home/avatar5.jpg'; // Add your avatar paths
import avatar6 from '../assets/Home/avatar6.png'; // Add your avatar paths

const items = [
  {
    image: homeImage1,
    text: "Intercultural experiences",
  },
  {
    image: homeImage2,
    text: "Join Us in Our Events",
  },
  {
    image: homeImage3,
    text: "Become a Member",
  },
  {
    image: homeImage4,
    text: "Learn More About Us",
  },
  {
    image: homeImage5,
    text: "Join Our Community",
  },
];

const testimonials = [
  {
    name: "Roberta Metsola",
    title: "President of the European Parliament",
    text: "The European Youth Parliament is of remarkable value as a platform to foster political engagement among European youth.",
    avatar: avatar1,
    link: "@RobertaMetsola on Twitter",
  },
  {
    name: "Asdin El Habbassi, BA",
    title: "Member of the Austrian Parliament",
    text: "The participation of young people is like the sun for a tree, indispensable in a strong Europe. Be a part of EYP. Your future, your Europe!",
    avatar: avatar2,
    link: "Austrian Parliament",
  },
  {
    name: "Mag. Maria Pichlbauer",
    title: "Counsellor for EU and Internationalisation at the local school authority in Styria",
    text: "I consider the EYP an ideal instrument for enhancing pan-European political education that at the same time provides language training and the development of knowledge and skills. I advise all secondary and vocational schools to join the EYP programme and take part in their activities.",
    avatar: avatar3,
  },
  {
    name: "Jochen Zöschg",
    title: "Zurich Insurance Group",
    text: "Young, highly committed people in intercultural cooperation. That is politics, society and economy – lived in a refreshing way. I was impressed by the energy and the positive approach to quite complex topics. Thank you for the inspiring exchange!",
    avatar: avatar4,
  },
  {
    name: "Robert Hietz",
    title: "Student and member of EYP Austria",
    text: "EYP doesn’t only mean getting new friends wherever you go, but also constantly being challenged to become better in every way. Apart from that, it means trying to find out what being European really means.",
    avatar: avatar5,
  },
  {
    name: "Emily Usner",
    title: "President of EYP Austria 2022/23",
    text: "EYP is the perfect combination of fun and learning, challenge and safety, individual development and collaboration. A youth organisation for the future of Europe.",
    avatar: avatar6,
  },
];

const CustomCard = styled(Card)(({ theme }) => ({
  borderRadius: '16px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'translateY(-10px)',
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  padding: '10px 20px',
  fontSize: '16px',
  fontWeight: 'bold',
  textTransform: 'none',
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  color: theme.palette.text.primary,
  textAlign: 'center',
  variant: 'h4',
  marginBottom: theme.spacing(4),
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: '16px',
  color: theme.palette.text.primary,
}));

const customNavButtons = (onClickHandler, hasNext, label) => (
  <IconButton
    onClick={onClickHandler}
    sx={{
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 10,
      ...(label === "prev" ? { left: 10 } : { right: 10 }),
      fontSize: '3rem'
    }}
  >
    {label === "prev" ? <ArrowBackIosIcon style={{ color: 'white', fontSize: '3rem' }} /> : <ArrowForwardIosIcon style={{ color: 'white', fontSize: '3rem' }} />}
  </IconButton>
);

const HomePage = () => {
    const { consent } = useConsent();
    const [news, setNews] = useState([]);
    const [nextEvent, setNextEvent] = useState(null);
    const navigate = useNavigate();
    const [quotes, setQuotes] = useState([]);

  
    useEffect(() => {
      const fetchNews = async () => {
        const db = getFirestore();
        const newsQuery = query(collection(db, 'news'), orderBy('timestamp', 'desc'), limit(3));
        const newsSnapshot = await getDocs(newsQuery);
        const newsData = newsSnapshot.docs.map(doc => doc.data());
        setNews(newsData);
      };
  
      const fetchNextEvent = async () => {
        const db = getFirestore();
        const currentTimestamp = new Date();
        const eventQuery = query(
          collection(db, 'events'),
          where('startDate', '>', currentTimestamp),
          orderBy('startDate', 'asc'),
          limit(1)
        );
        const eventSnapshot = await getDocs(eventQuery);
        const eventData = eventSnapshot.docs.map(doc => doc.data())[0];
        setNextEvent(eventData);
      };
      // const fetchQuotes = async () => {
      //   const db = getFirestore();
      //   const quotesQuery = query(collection(db, 'quotes'));
      //   const quotesSnapshot = await getDocs(quotesQuery);
      //   const quotesData = quotesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      //   setQuotes(quotesData);
      // };
  
      // fetchQuotes();
      fetchNews();
      fetchNextEvent();
    }, []);

    

  
    // useEffect(() => {
    //   // Force a re-render after the initial render to ensure the carousel height is correct
    //   setCarouselHeight('100%');
    // }, []);

  return (
    <div>
      <Carousel
        NextIcon={<ArrowForwardIosIcon />}
        PrevIcon={<ArrowBackIosIcon />}
        navButtonsAlwaysVisible
        navButtonsProps={{
          style: {
            backgroundColor: 'transparent',
            borderRadius: 0,
            margin: 0,
            padding: 0,
          }
        }}
        navButtonsWrapperProps={{
          style: {
            top: 'calc(50% - 20px)',
            height: '40px',
          }
        }}
        navButton={({ onClickHandler, hasNext, label }) => customNavButtons(onClickHandler, hasNext, label)}
        height={600} // Set height to 600px
      >
        {items.map((item, index) => (
          <Paper key={index} style={{ position: 'relative', height: '100%' }}>
            <img src={item.image} alt={item.text} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            <Box
              sx={{
                position: 'absolute',
                bottom: '20px',
                left: '20px',
                color: 'white',
                textAlign: 'left',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                padding: '10px',
                borderRadius: '10px',
              }}
            >
              <Typography variant="h4" component="div">
                {item.text}
              </Typography>
            </Box>
          </Paper>
        ))}
      </Carousel>

      <Container maxWidth="lg" sx={{ mt: 8 }}>
        <SectionTitle variant="h2">Discover Our Programs</SectionTitle>
        <Grid container spacing={6}>
          <Grid item xs={12} md={4}>
            <CustomCard>
              <CardMedia component="img" height="200" image={homeImage1} alt="for Students" />
              <CardContent>
                <CustomTypography variant="h5">For Students</CustomTypography>
                <CustomButton variant="contained" color="primary" fullWidth onClick={() => navigate(`/about-us`)}>
                  Learn More
                </CustomButton>
              </CardContent>
            </CustomCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomCard>
              <CardMedia component="img" height="200" image={homeImage2} alt="for Teachers" />
              <CardContent>
                <CustomTypography variant="h5">For Teachers</CustomTypography>
                <CustomButton variant="contained" color="primary" fullWidth onClick={() => navigate(`/about-us`)}>
                  Learn More
                </CustomButton>
              </CardContent>
            </CustomCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomCard>
              <CardMedia component="img" height="200" image={homeImage3} alt="for Partners" />
              <CardContent>
                <CustomTypography variant="h5">For Partners</CustomTypography>
                <CustomButton variant="contained" color="primary" fullWidth onClick={() => navigate(`/partner`)}>
                  Learn More
                </CustomButton>
              </CardContent>
            </CustomCard>
          </Grid>
        </Grid>

        <Divider sx={{ my: 8 }} />

        <SectionTitle variant="h2">Upcoming Events & Opportunities</SectionTitle>
        <Grid container spacing={6}>
      <Grid item xs={12} md={4}>
        <CustomCard sx={{ textAlign: 'center', p: 4 }} onClick={() => navigate(`/events`)} style={{ cursor: 'pointer' }}>
          <CustomTypography variant="h6">Next Event</CustomTypography>
          {nextEvent ? (
            <Typography variant="body1" sx={{ mt: 2 }}>
              {nextEvent.title} <br />
              {new Date(nextEvent.startDate.toDate()).toLocaleString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })} - {new Date(nextEvent.endDate.toDate()).toLocaleString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </Typography>
          ) : (
            <Typography variant="body1" sx={{ mt: 2 }}>
              No upcoming events found.
            </Typography>
          )}
        </CustomCard>
      </Grid>
      <Grid item xs={12} md={4}>
        <CustomCard sx={{ textAlign: 'center', p: 4 }} onClick={() => navigate('/membership')} style={{ cursor: 'pointer' }}>
          <CustomTypography variant="h6">Sign up to the Newsletter</CustomTypography>
          <Box component="img" src={homeImage2} alt="Newsletter" sx={{ width: '100%', mt: 2, borderRadius: '16px' }} />
        </CustomCard>
      </Grid>
      <Grid item xs={12} md={4}>
        <CustomCard sx={{ textAlign: 'center', p: 4 }} onClick={() => navigate('/membership')} style={{ cursor: 'pointer' }}>
          <CustomTypography variant="h6">Join Us</CustomTypography>
          <Box component="img" src={homeImage1} alt="Join Us" sx={{ width: '100%', mt: 2, borderRadius: '16px' }} />
        </CustomCard>
      </Grid>
    </Grid>

        <Box sx={{ mt: 6, marginBottom: '2vw' }}>
          <Typography variant="h4" component="div" sx={{ mb: 4, textAlign: 'center' }}>
            Others on EYP
          </Typography>
          <Grid container spacing={6}>
            {testimonials.map((testimonial, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Box display="flex" alignItems="flex-start">
                  <Avatar src={testimonial.avatar} sx={{ width: 80, height: 80, mr: 2 }} />
                  <Box>
                    <Typography variant="body1">{testimonial.text}</Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                      - {testimonial.name}, {testimonial.title}
                    </Typography>
                    {testimonial.link && (
                      <Typography variant="body2" color="primary">
                        {testimonial.link}
                      </Typography>
                    )}
                  </Box>
                </Box>
                {index % 2 !== 0 && index < testimonials.length - 1 && <Divider sx={{ my: 4 }} />}
              </Grid>
            ))}
          </Grid>
        </Box>

        <SectionTitle variant="h2">Latest News & Social Updates</SectionTitle>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <Box sx={{ textAlign: 'center' }}>
              <CustomTypography variant="h6">Latest News</CustomTypography>
              {news.map((item, index) => (
                <CustomCard key={index} sx={{ mb: 4, p: 2 }}>
                  <Typography variant="h6">{item.title}</Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                    {new Date(item.timestamp.toDate()).toDateString()}
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    {item.content}
                  </Typography>
                </CustomCard>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ textAlign: 'center' }}>
            <CustomTypography variant="h6">Latest Instagram Post</CustomTypography>

            {consent.thirdParty && (
              <InstagramEmbed
                url="https://www.instagram.com/reel/C5VVwxxxSK8/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                width="100%"
                maxWidth={540}
                hideCaption={false}
                containerTagName="div"
                protocol=""
                injectScript
                onLoading={() => <CircularProgress />}
                onSuccess={() => console.log('Loaded')}
                onAfterRender={() => console.log('Rendered')}
                onFailure={(error) => console.error('Error:', error)}
              />
            )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default HomePage;
