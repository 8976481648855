import React, { useEffect, useState } from 'react';
import { Container, Typography, Card, CardContent, CardMedia, Grid, Avatar, Box } from '@mui/material';
import { useParams, Link } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const EventDetail = () => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);

  useEffect(() => {
    const fetchEvent = async () => {
      const db = getFirestore();
      const docRef = doc(db, 'events', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setEvent(docSnap.data());
      } else {
        console.log('No such document!');
      }
    };

    fetchEvent();
  }, [id]);

  if (!event) return <Typography>Loading...</Typography>;

  const sessionTypeDisclaimer = {
    regional: (
      <>
        This is a Regional Session. A Regional Session is a two- or three-day session. Selected participants will be invited to participate in the National Selection Conference. To find out more about such events or the European Youth Parliament in general, please visit <Link to="/about-us">About us</Link>.
      </>
    ),
    national: (
      <>
        This is a National Selection Conference. A National Selection Conference is a three- or four-day session for young participants from all over Austria. Selected Delegates will be invited to represent Austria at International Sessions and other international EYP events. To find out more about such events or the European Youth Parliament in general, please visit <Link to="/about-us">About us</Link>.
      </>
    ),
    mini: (
      <>
        This is a mini session. A Mini Session is a one-day event aiming to spread knowledge and spark engagement. To find out more about such events or the European Youth Parliament in general, please visit <Link to="/about-us">About us</Link>.
      </>
    ),
    other: "",
  };

  const formatDate = (timestamp) => {
    const date = timestamp.toDate();
    return date.toLocaleDateString();
  };

  return (
    <Container>
    <Card 
      sx={{
          boxShadow: 0,
      }}
    >
      <CardMedia
        component="img"
        height="300"
        image={event.headerImageUrl}
        alt={event.title}
      />
      {event.logoUrl && (
        <Box
          component="img"
          src={event.logoUrl}
          alt={event.title}
          sx={{
            position: 'relative',
            top: '-100px',
            margin: '0 auto',
            display: 'block',
            height: '200px',
            objectFit: 'contain',
            zIndex: 1
          }}
        />
      )}
    </Card>
      <Box textAlign="center" mt={0} mb={4}>    
        <Typography variant="h3" gutterBottom>{event.title}</Typography>
        <Typography variant="h5" gutterBottom>{formatDate(event.startDate)} - {formatDate(event.endDate)}</Typography>
        {event.sessionType && (
          <Box
            sx={{
              border: '1px solid #ccc',
              padding: '16px',
              borderRadius: '4px',
              backgroundColor: '#f9f9f9',
              display: 'inline-block',
              marginTop: '16px',
              marginBottom: '16px',
            }}
          >
            <Typography variant="body1" color="textSecondary">
              {sessionTypeDisclaimer[event.sessionType]}
            </Typography>
          </Box>
        )}
        <Typography variant="body1" gutterBottom dangerouslySetInnerHTML={{ __html: event.description }} />
      </Box>
      <Typography variant="h5" gutterBottom>Contact</Typography>
      <Grid container spacing={2}>
        {event.contacts.map((contact, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <Card>
              <CardContent>
                <Box display="flex" alignItems="center">
                  <Avatar src={contact.imageUrl} sx={{ width: 56, height: 56, mr: 2 }} />
                  <Box>
                    <Typography variant="h6">{contact.name}</Typography>
                    <Typography variant="body2">{contact.role}</Typography>
                    <Typography variant="body2">{contact.email}</Typography>
                    <Typography variant="body2">{contact.phone}</Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Typography variant="h5" gutterBottom>Partners</Typography>
      <Grid container spacing={2}>
        {event.partners.map((partner, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <Card>
              <CardContent>
                <Box display="flex" alignItems="center">
                  <Avatar src={partner.logoUrl} sx={{ width: 56, height: 56, mr: 2 }} />
                  <Box>
                    <Typography variant="h6">{partner.name}</Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default EventDetail;
